
const config: IConfig = {
  serve: 'https://docs-backend.hahamiao.com',
  keys: ['some secret hurr'],
  session: {
    key: 'koa:sess',
  },
}

export default config
